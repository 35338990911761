@font-face {
  font-family: "Balgin";
  src: url("./fonts/Balgin/Balgin-SemiBoldSmExpanded.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CSS */
a {
  color: #776b5d;
}

.tiptap {
  outline: none;
}

.ProseMirror {
  min-height: 300px;
  overflow: auto;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Mui Dialog zIndex is set to 1500, so we need to override that for Google Maps Autocomplete */
.pac-container {
  background-color: #fff;
  z-index: 2000 !important;
  position: fixed;
}

.pac-item {
  font-family: "Open Sans", Arial, sans-serif;
}
